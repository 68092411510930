import React from 'react'

const PopUpCol = ({active,data,close}) => {
  return (
    <div id="pop-up-col" className={active===true?'active':''}>
            <div className='pop-container'>
                <div className='close' onClick={()=>{close();}}>✖</div>
                {data}
            </div>
    </div>
  )
}

export default PopUpCol;
