import React, { Component } from 'react';
import TabsContainer from './TabsModule/TabsContainer'


class Dashboard extends Component{
    render(){
        return(
            <>
                <h1>{this.props.currentViewTitle}</h1>
                <TabsContainer tabContent={this.props.dashboard} />
            </>
            
        );
    }
}

export default Dashboard;