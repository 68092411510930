import React from 'react';
import { NavLink} from 'react-router-dom';



const MainLi = ({label,url}) => {
    return (
        <li><NavLink to={`${url}`}>{label}</NavLink></li>
    );
}

export default MainLi;