import React,{PureComponent} from 'react';
import TabsMenu from './TabsMenu';
import TabsContent from './TabsContent';

class TabsContainer extends PureComponent{
    constructor(){
        super();
        this.state={
            selected:0
        }
    }

    handleChangeTab=(index)=>{
        console.log(index);
        this.setState(prevState=>{
            return {
                selected: index
            }
        });
    }

    render(){
        return (
            <div className='tabs-container'>
                <TabsMenu 
                    selected={Object.keys(this.props.tabContent)[this.state.selected]}
                    options={Object.keys(this.props.tabContent)}
                    changeTab={this.handleChangeTab}
                />

                <TabsContent 
                    content={Object.values(this.props.tabContent)[this.state.selected]}
                />
            </div>
            
        );
    }
}

export default TabsContainer