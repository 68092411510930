import React from 'react'

const Field = ({data,value}) => {
    const type = data.htmlType;
    if(type === "input"){
        let maxLength = data.maxLength !== 'None' ? data.maxLength : null;
        return ( <input name={data.name} placeholder={data.label} type={data.type} maxLength={maxLength} defaultValue={value}/> );
    }else if(type === "textarea"){
        let maxLength = data.maxLength !== 'None' ? data.maxLength : null;
        return ( <textarea name={data.name} placeholder={data.label} type={data.type} maxLength={maxLength} defaultValue={value}/> );
    }
    else if(type === "select"){
        let options;
        if(data.options!==undefined){
            
        }
        return ( 
            <select name={data.name} placeholder={data.label} type={data.type} defaultValue={value}>
                <option value="0">-- Seleccione {data.label} --</option>
            </select> 
        );
    }
}

export default Field