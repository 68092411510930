import React, { Component } from 'react'
import axios from 'axios';
import Table from '../../components/Table/Table';
import Form from '../../components/Form/Form';
import PopUpCol from '../../components/PopUpCol/PopUpCol';


class Master extends Component {
  state={
    data:{},
    endpoints:[],
    companies:[],
    form:{},
    popUp:{active:false,type:''},
    popUpContent:'',
    barButtons:{},
    table:""
  }

  createModule=()=>{
    
    this.setState(prevState=>{
      if(prevState.popUp.active===false){
        const popUpCol =  
        <>
          <h2>Crear</h2>
          <Form structure={this.state.form.create.formModel} label={`Crear`} action={this.state.form.create.action} />
        </>;
        return {popUp:{active:true,type:'create'},popUpContent:popUpCol};
      }else{
        return {popUp:{active:false,type:''},popUpContent:''};
      }
    });
  }

  readModule=(rowObj)=>{
    this.setState(prevState=>{
      if(prevState.popUp.active===false){
        console.log(rowObj);
        const popUpCol =  
        <>
          <h2>Read</h2>
          <table>
            <tbody>
              {Object.keys(rowObj).map((key, index)=>{
                return <tr key={index}>
                        <td>{key}</td><td>{rowObj[key]}</td>
                      </tr>
              })}
            </tbody>
          </table>
        </>;
        return {popUp:{active:true,type:'read'},popUpContent:popUpCol};
      }else{
        return {popUp:{active:false,type:''},popUpContent:''};
      }
    });
  }

  updateModule=(rowObj)=>{
    this.setState(prevState=>{
      if(prevState.popUp.active===false){
        const popUpCol =  
        <>
          <h2>Editar</h2>
          <Form structure={this.state.form.create.formModel} label={`Editar`} action={this.state.form.create.action} values={rowObj}/>
        </>;
        return {popUp:{active:true,type:'update'},popUpContent:popUpCol};
      }else{
        return {popUp:{active:false,type:''},popUpContent:''};
      }
    });
  }

  closePopUp=()=>{
    this.setState(prevState=>{
      return {popUp:{active:false,type:''},popUpContent:''};
    });
  }
  
  componentWillMount(){
    this.index();
  }

  index = (query='') =>{
    let data = this.props.context.find(op =>{ 
      return op.type === this.props.route.match.params.type; 
    });
    let endpoints = {}
    Object.entries(process.env).forEach(([key,value])=>{
      if(key.includes(data.env)){
          endpoints[key.split('__')[1]] = value;
      }
    });
      console.log(`${process.env.REACT_APP_BACKEND}${endpoints.ALL}`);
      axios.get(`${process.env.REACT_APP_BACKEND}${endpoints.ALL}`)
      .then(response => {
        console.log(response);
        let actions = {read:this.readModule,update:this.updateModule};
        this.setState({
          data:data,
          endpoints:endpoints,
          companies:response.data.data,
          table:<Table JSONdata={response.data.data} actions={actions}/>
        });
        return endpoints.CREATE
      })
      .then(createEndpoint=>{
        console.log(createEndpoint);
        this.createTemplate(createEndpoint);
      })
      .catch(error => {
        console.log('Error fetching and parsing data from axios', error);
      }); 
  }

  createTemplate=(createEndpoint)=>{

    // console.log(query);
      axios.get(`${process.env.REACT_APP_BACKEND}${createEndpoint}`)
      .then(response => {
        console.log(response);
        this.setState(prevState=>{
          let temp = prevState.form
          temp.create = {formModel:response.data.formModel,action:response.data.action}
          return {form:temp }
        });
      })
      .then(()=>{
        this.setState(prevState=>{
          var temp = prevState.barButtons;
          temp.create = <button className="btn-blue" type="button" onClick={()=>{this.createModule();}}>Crear</button>
          return { barButtons:temp }
        });
      })
      .catch(error => {
        console.log('Error fetching and parsing data from axios', error);
      }); 


  }


  render(){
    return (
      <>
        <h1>{this.state.data.label}</h1>
        <div className='flex'>
          {this.state.barButtons.create}
        </div>
        <PopUpCol active={this.state.popUp.active} data={this.state.popUpContent} close={this.closePopUp}/>
        {this.state.data.length > 0 ? 
            this.state.table : "No existen resultados en la consulta"
        }
      </>
      )
    }
}

export default Master