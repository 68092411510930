import Reach from 'react';

const TabsMenu=({selected,options,changeTab})=>{
    let tabs = options.map((tab,i) => {
              return <div key={i} className={`${tab == selected ? "selected": ''} tab`} onClick={()=>changeTab(i)}>{tab}</div>;
            });
    return(
        <div className="tabs flex">
            {tabs}
        </div>
    );
}

export default TabsMenu;