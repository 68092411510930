import React from 'react';

const Row=({rowObj,actions})=>{
    return(
        <tr>
            {Object.keys(rowObj).map((key, index)=>{
                return <td key={index}>{rowObj[key]}</td>
            })}
            <td>
                <button type="button" onClick={()=>{actions.read(rowObj)}}>Ver</button>
                <button type="button" onClick={()=>{actions.update(rowObj)}}>Editar</button>
                <button type="button" >Eliminar</button>
            </td>
        </tr>
    );
}

export default Row;