import React, { Component } from 'react'
import Field from './Field';

class Form extends Component{
  state={
    action:this.props.action,
  }

  submit(){
    alert('this');
  }

  render(){
    if(this.props.structure!== undefined){
      const  fields = this.props.structure.map((field,i)=>{
      var theValue='';
        if(this.props.values!==undefined){
          var theValue = this.props.values[field.name];
        }
        return <Field key={i} data={field} value={theValue} />
      });
      
      return (
        <form>
          {fields}
          <button className="btn-blue" type="button" onClick={()=>{this.submit();}} >{this.props.label}</button>
        </form>
        )
      }
    }
}

export default Form