import React, {Component} from 'react';
import MainMenu from './components/Menu/MainMenu';
import './App.css';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Master from './pages/Master/Master.js';
import NewsFeedContainer from './components/Feed/NewsFeedContainer';
import Cookies from 'universal-cookie';


// Context
import { AppContextProvider,AppContextConsumer } from "./components/AppContext/AppContext";


class App extends Component{
  
  constructor(){
    super();
    this.state={
      isLogged:false,
      session:false,
      layout:{
        class:"flex"
      },
      colorScheme:"scheme-A",
      view:{
        mainTitle:"Comunicaciones",
        menuOptions:[
          {label:"Home",url:"/"},
        ],
        masters:[
          // {label:"Usuarios",url:"/master", env:"USERS",type:"users"},
          // {label:"Companias",url:"/master", env:"COMPANIES",type:"companies"},
          // {label:"Compania Departamento",url:"/master", env:"COMPANY_DEPARTMENT_USER",type:"company-departments-user"},
          // {label:"UsuarioCompania Departamento",url:"/master", env:"COMPANY_DEPARTMENT",type:"company-departments"}
        ],
        dashboard:{
          acciones:[
              // {
              //   label:"Tickets",
              //   content:[
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //   ]
              // },
              // {
              //   label:"Tareas EOS",
              //   content:[
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //   ]
              // },
              // {
              //   label:"Reportes EOS",
              //   content:[
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //     {title:"Titulo",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
              //   ]
              // }           
          ],
          indicadores:{}
        },
        news:{
          todas:[
            // {
            //     label:"",
            //     content:[
            //       {title:"Titulo de Noticia",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
            //       {title:"Titulo de Noticia",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
            //       {title:"Titulo de Noticia",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
            //       {title:"Titulo de Noticia",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
            //     ]
            //   }
          ],
          // dept2:[
          //   {
          //       label:"",
          //       content:[
          //         {title:"Titulo de Dept02",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
          //         {title:"Titulo de Dept02",author:"Arnaldo",date:'2022-05-12',content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit"},
          //       ]
          //     }
          // ]
        }
      }
      
    }
  }

  handleLog=(data)=>{
    const cookie = new Cookies();
    if(!data){
      // log out
      cookie.remove('core_session');
      this.setState({isLogged:false,session:false});
    }else{
      // log in
      cookie.set('core_session', JSON.stringify(data));
      this.setState({isLogged:data.loggedin,session:data});
    }
  }
  
  session(){
    const cookie = new Cookies();
    let session = cookie.get('core_session');
    // const token = sessionStorage.getItem('session');
    return session === null || session === undefined ? false:session;
  }

  componentDidMount(){
    let session = this.session();
    this.setState({session:session});
  }

  render(){
    
    if( !this.state.isLogged && !this.session() ){
      return( <Login handleLog={this.handleLog} /> );
      }else{
        return (
            <BrowserRouter>
            <div className={`${this.state.colorScheme} viewport`}>
              <header className=""></header>          
              <main className={`${this.state.layout.class} main-bg`}>
                <MainMenu menuOptions={[...this.state.view.menuOptions,...this.state.view.masters]} handleLog={this.handleLog}/>

                <div id="" className='workspace'>
                  <AppContextProvider value={{masters:this.state.view,session:this.state.session}} >
                    <Switch>
                      <Route exact path="/log-in/"><Login /></Route>
                      <AppContextConsumer>
                        {context=>{
                          return <>
                          <Route exact path="/"><Home context={context}/></Route>
                          <Route path="/master/:type" component={(route) =>{return <Master context={context} route={route}/> }}></Route>
                          </>
                          }
                        }
                      </AppContextConsumer>
                    </Switch>
                  </AppContextProvider>
                </div>

                <NewsFeedContainer allNews={this.state.view.news} />  
              </main>
            </div>
            </BrowserRouter>
      );
    }
  }
}

export default App;
