import React from 'react';
import Card from '../Card';

const Feed=({content})=>{
    let cards = content.content.map((content,i)=>{
        return <Card key={i} delay={i/10} cardContent={content} />;
    });
    return(
        <div className='feed-column'>
            <h2>{content.label}</h2>
            <div className='feed'>
                <div className='feed-content'>
                    {cards}
                </div>
            </div>
        </div>
    );
}

export default Feed;