import Reach from 'react';
import Feed from '../Feed/Feed';

const TabsContent=({content})=>{
    let contentContainer = content.map((content,i)=>{
        return <Feed key={i} content={content} />;
    });
    return(
        <div className="tabs-content flex">
            {contentContainer}
        </div>
    );
}

export default TabsContent;