import React,{Component} from 'react';
import TabsContainer from '../TabsModule/TabsContainer';


class NewsFeedContainer extends Component{
    render(){
        return (
                <div className='news-feed'>
                    <h2>Noticias</h2>
                    {/* <button>Nuevo Ticket +</button> */}
                    <TabsContainer 
                        tabContent={this.props.allNews}
                    />
                </div>
        );
    }
}

export default NewsFeedContainer;