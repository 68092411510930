import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { NavLink} from 'react-router-dom';

class Login extends Component{

    state={
        step:0,
        steps:{},
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            xsrfCookieName: 'csrf_access_token',
            crossDomain: true,
            // "Access-Control-Allow-Origin": `http://localhost:3000/`,
            "Access-Control-Allow-Origin": `${process.env.REACT_APP_FRONTEND}`,
            //"Access-Control-Allow-Headers": 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
            //"Access-Control-Allow-Credentials": true,
            mode: 'cors',
            // 'Authorization': `Bearer ${pin}`
        },
        crossDomain: true,
        //credentials: 'include',
        //'withCredentials': true,
        reset:false,
        msg:""
    }

    componentDidMount(){
        let steps={
            0:this.loginForm,
            1:this.sendUserBackEndForm,
            2:this.emailSent,
            3:this.passwordReseted,
        }
        this.setState({steps:steps})
    }

    handleLostPassword=(arg=0,msg=false)=>{
        console.log("B");
        this.setState({step:arg})
        return this.state.steps[arg](msg);
    }


    //LOGIN ---------------------------------------------------------------------------------------

    loginForm=(msg=false)=>{
        console.log(msg);
        return(
            <>
            <form action="/" method="GET" onSubmit={(e)=>{e.preventDefault();this.logInAction(e)}}>
                <h1>Bienvenido</h1>
                <div>{this.state.msg}</div>
                <input name="username" type="text" placeholder='Correo electrónico'/>
                <input name="password" type="password" placeholder='Contraseña'/>
                <button type="submit" className='btn btn-green'><span>Acceder</span></button>
            </form>
            <p className="anchor pad-y-20px" onClick={
                ()=>{this.handleLostPassword("1","A continuación te enviaremos un código de recuperación a tu correo.")}
            }>
                ¿Olividaste tu contraseña?
            </p>
            </>
        )
    }

    logInAction(e){
        let form = new FormData(e.target);
        const options = {
            method: 'POST',
            url: `${process.env.REACT_APP_BACKEND}/signin`,
            data: {
                username: form.get('username'),
                password: form.get('password'),
            }
        };
        axios.defaults.withCredentials = true
        axios.request(options)
        .then(response=>{
            if(response.status===200){
                this.logIn(response);
            }else{}
        }).catch(error=>{
            // WRONG PASS
            let msg = <div>Contraseña o usuario incorrecto. Por favor, inténtelo de nuevo. 
                <span className="anchor" onClick={
                ()=>{this.handleLostPassword("1","A continuación te enviaremos un código de recuperación a tu correo.")}
            }>
                ¿Olividaste tu contraseña?
            </span></div>
            this.setState({msg:msg})
            console.error(error);
        });


    }

    logIn=(response)=>{
        console.log(response);
            // localStorage.setItem ( 'session', JSON.stringify(response.data) );
            // localStorage.setItem ( 'pin', getCookie ( 'csrf_access_token' ) );
            // sessionStorage.setItem ( 'session', JSON.stringify(response.data) );
            // sessionStorage.setItem ( 'pin', getCookie ( 'csrf_access_token' ) );
            this.props.handleLog(response.data);
            return response.data;
    }
    //END OF LOGIN ---------------------------------------------------------------------------------------



    // STEP 01 ---------------------------------------------------------------------------------------
        // FORM
        sendUserBackEndForm=()=>{
            return(
                <>
                <h1>Restablecer contraseña</h1>
                <form action="/" method="POST" onSubmit={(e)=>{e.preventDefault();this.sendUserBackEndAction(e)}}>
                    <p>A continuación te enviaremos un código de recuperación a tu correo.</p>
                    <p>{this.state.msg}</p><br/>

                    <input name="email" type="email" placeholder='Correo electrónico' />
                    <button type="submit" className='btn-blue'>Enviar código</button>
                </form>
                <p className="anchor pad-y-20px"><a href='/'>Volver a inicio</a></p>
                </>
            )
        }

        // ACTION
        sendUserBackEndAction(e){
            let form = new FormData(e.target);
            const options = {
                method: 'POST',
                url: `${process.env.REACT_APP_BACKEND}/password-recovery`,
                data: {
                    email: form.get('email')
                },
                headers:this.state.headers
            };
            axios.request(options)
            .then(response=>{
                console.log(response);
                    if(response.status===200){
                        this.handleLostPassword(2,response.data.msg);
                    }else{
                        // this.handleLostPassword(2,response.data.msg);
                    }
            }).catch(error=>{
                this.setState({msg:"Perdone, este correo no esta registrado en la organización."})
                console.error(error);
            });
        }


    //END OF STEP 01 ---------------------------------------------------------------------------------------

    // STEP 02 ---------------------------------------------------------------------------------------

    //EMAIL SENT

        emailSent=()=>{
            return(
            <>
                <p>Correo enviado exitosamente. Revise su bandeja de entrada.</p>
                <a href="/" className="anchor pad-y-20px"> Volver al inicio </a>
            </>
            );
        }

         // FORM
        gotTokenForm=()=>{
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const cr = urlParams.get('cr');
            return(
                <>
                <h1>Nueva contraseña</h1>
                <p>{this.state.msg}</p><br/>
                <form action="/" method="POST" onSubmit={(e)=>{e.preventDefault();this.resetPassword(e)}}>
                    <input name="password" type="password" placeholder='Nueva contraseña' />
                    <input name="password2" type="password" placeholder='Confirmar contraseña' />
                    <input name="token" type="hidden" value={cr} />
                    <button type="submit" className='btn-blue'>Crear nueva contraseña</button>
                </form>
                </>
            )
        }


        // ACTION
        resetPassword=(e)=>{
            let form = new FormData(e.target);
            if(form.get('password')!=form.get('password2')){
                this.setState({msg:"Por favor, asegúrese de que ambas contraseñas sean iguales\n"});
                return;
            }
            const options = {
                method: 'POST',
                url: `${process.env.REACT_APP_BACKEND}/reset-password`,
                data: {
                    password: form.get('password'),
                    token: form.get('token')
                },
                headers:this.state.headers
            };
            // this.handleLostPassword(3);



            axios.request(options)
            .then(response=>{
                console.log(response);
                    if(response.status===200){
                        this.handleLostPassword("0","hola!");
                            this.handleLostPassword(3);

                    }else{
                        this.handleLostPassword("-2",response.data.msg);
                    }
            }).catch(error=>{
                console.error(error);
            });
        }

        passwordReseted=()=>{
            setTimeout(() => {
                document.location.replace("/");
            }, 5000);
            return(
            <>
                <p>Su contraseña ha sido reestablecida de manera exitosa. En breve será redirigido al inicio para que pueda volver a acceder con sus nuevas credenciales. </p>
                <a href="/" className="anchor pad-y-20px"> Volver al inicio </a>
            </>
            );

        }

    //END OF STEP 02 ---------------------------------------------------------------------------------------

    render(){
        const path = window.location.pathname;
        var skip = false;
        if(path==='/confirmation-recovery/' && this.state.step !== 3){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const cr = urlParams.get('cr');
            if(cr!==undefined){
                skip=true;
            }
        }
        return (
            <>
            <div id="login-page" className='container no-pad'>
                <div className='flex flex-even no-gap col-body full-height'>
                    <div className='container container-center'>

                        <div id="login">
                            <img src="/images/icon.svg" alt='logo' className='logo'/>
                            {skip ===true? this.gotTokenForm() : this.state.step === 0 ? this.loginForm() : this.state.steps[this.state.step]()} 
                        </div>
                    </div>
                    <div id="img-col"></div>
                </div>
            </div>
            </>
            )
        }
}

export default Login
