import React, {Component} from 'react';
import Row from './Row';

class TBody extends Component{
    state={}
    render(){
        
        let rows = this.props.data.map((obj,i)=>{
            if(i <= parseInt(this.props.limit) || this.props.limit === -1){
                return <Row key={i} rowObj={obj} actions={this.props.actions}/>
            }

        });
        return(
            <tbody>
                {rows}
            </tbody>
        );
    }
}

export default TBody;


