import React, { Component } from 'react'
import axios from 'axios';
import Cookies from 'universal-cookie';

class Trac extends Component {

    connect=()=>{
        const cookies = new Cookies();
        console.log(cookies.get("core_session"));

        axios.defaults.withCredentials = true
        return axios
        .get ( `${process.env.REACT_APP_BACKEND}/apps/erp/auth`,
        {
            headers: {
              "Access-Control-Allow-Headers": 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin, Set-Cookies',
              'Content-Type': 'application/json; charset=utf-8',
              'Accept': 'application/json',
              xsrfCookieName: 'csrf_access_token',
              'Access-Control-Allow-Origin': `${process.env.REACT_APP_FRONTEND}`,
              'Access-Control-Allow-Credentials': true,
              'X-CSRF-TOKEN-ACCESS': cookies.get("core_session").token,
              mode: 'cors',
              // 'Authorization': `Bearer ${pin}`
            },
            crossDomain: true,
            credentials: 'include',
            withCredentials: true
        }
        )
        .then (response => {
          console.log(response);
          if ( response.data.tracAuth === true ) {

            let win = window.open ( `https://trac.somosadvance.com/#/?token=${response.data.tokenTrac}`, '_blank' );
            win.focus ();

          } else {

            // Que hacer? alguna accion si no hay respuesta
            // nunca se definio que hacer en este caso
          }
        }
        )
        .catch (
        e => {

            console.log ( e );
        }
        );

    }

  render() {
    return (
      <div className='app-icon' onClick={()=>{this.connect()}}>
          <img alt='app icon' src='/images/trac-icon.png'/>
          <p>Trac</p>
      </div>
    )
  }
}

export default Trac
