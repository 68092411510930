import React, {Component} from 'react';
import Dashboard from '../../components/Dashboard';
import Trac from '../../components/ExternalApps/Trac';


class Home extends Component{
    state={}
    render(){
        return(
        <>
            <div className='center-x-y'>
            <h1>{this.props.context.session!==undefined?this.props.context.session.name:""}, te damos la bienvenida a Advance Core</h1>
            <p>Este proyecto aun se encuentra en construcción. <br/>Sin embargo, puedes hacer uso del mismo para accesar cualquiera de las siguientes aplicaciones</p>
                {/* <Dashboard 
                    currentViewTitle={this.props.view.mainTitle}
                    dashboard={this.props.view.dashboard}
                /> */}
                <br/>
                <div className='flex'>
                    <Trac/>
                </div>
            </div>
            <div className='flex values-footer'>
                <p>Cooperación</p>
                <p>Calidad</p>
                <p>Crecimiento</p>
                <p>Compromiso</p>
                <p>Creación</p>
            </div>
        </>
        );
    }
}

export default Home;