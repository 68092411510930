import React from 'react';
import MenuLi from './MenuLi';

const MainMenu = ({menuOptions,handleLog}) => {
    let options = menuOptions;
    return (
        <nav>
            <img src="/images/icon.svg" alt='logo' className='logo'/>
            <ul>
                {options.map((op,i)=>{
                    let type = op.hasOwnProperty('env') ? `/${op.type}` : "" ;
                    return <MenuLi 
                                key={i}
                                label={op.label}
                                url={op.url + type}
                            />;
                    })
                }
                <li><a href="/" onClick={()=>{handleLog(false)}}>Cerrar Sesión</a></li>
            </ul>
        </nav>
    );
}

export default MainMenu;