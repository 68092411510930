import React, {PureComponent} from 'react';
import TBody from './TBody';
import DatalistInput from 'react-datalist-input';


class Table extends PureComponent{
    state={
        sort:"id",
        limit:10,
        order:1,
        ranges:[10,30,50,100],
        data:[],
        head:[]
    }

    sortData=(key=this.state.sort)=>{
        var data = this.state.data ;
        let output = data.sort(this.sortBy(key));
        console.log(output);
        if(this.state.order===-1){output.reverse();}
        this.setState({data:output})
    }

    sortBy(key,sortOrder=1) {
        return (a,b)=>{
            var result = (a[key] < b[key]) ? -1 : (a[key] > b[key]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    changeSort=(key)=>{
        this.setState(prevState=>{
            let order = prevState.sort === key ? this.state.order * -1 : 1 ; 
            let output = this.state.data.sort(this.sortBy(key,order));
              return {
                  sort: key,
                  order: order,
                  data:output
              }
          }
      ); 
    }

    getTableHead=(data)=>{
        if(data==undefined || data==null || data.length===0){return;}
        let output = Object.keys(data[0]).map((th,i)=>{
            return <th key={i} className={`${th === this.state.sort ? "selected": ''} ${this.state.order == 1 ? "ASC": 'DESC'}`}>
                <div onClick={()=>{this.changeSort(th)}}>{th.replace("_"," ")}</div> <span>▼</span>

                <DatalistInput
                    placeholder={th.replace("_"," ")}
                    onSelect={(item) => console.log(item.value)}
                    items={data.map((d,j)=>{ return {id:d[th],value:d[th]} })}
                />
                </th>
        });
        return output;
    }

    changeRange=(i)=>{
        this.setState(prevState=>{
              return {
                  limit:i-1
              }
          }
      ); 
    }

    componentDidMount(){
        this.setState({data:this.props.JSONdata,head:this.getTableHead(this.props.JSONdata)})
    }



    render(){
        return(
        <>
            <span>{this.state.limit} de {this.state.data.length} elementos</span>
            <div id="table-container">
                <table>
                    <thead>
                        <tr>
                            {this.state.head}
                            <th id="actions">Acciones</th>
                        </tr>
                    </thead>
                    <TBody data={this.state.data} limit={this.state.limit} actions={this.props.actions}/>
                </table>
            </div>
                <select onChange={(e)=>{this.changeRange(e.target.value)}}>
                    {this.state.ranges.map(r=>{
                        return <option key={r} value={r}>{r}</option>
                    })}
                    <option key="999" value={this.state.data.length}>Todos</option>
                </select>
        </>
        );
    }
}

export default Table;


