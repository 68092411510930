import React,{PureComponent} from 'react';

class Card extends PureComponent{

    state={
        openCard:false
    }

    handleCardOpen=()=>{
        this.setState(prevState=>{
            return {
                openCard: !prevState.openCard
            }
        });
    }

    render(){
            let {title,author,date,content}=this.props.cardContent;
            return(
                <div className={`${this.state.openCard ? 'open' : ''} card`} style={{"--delay":`${this.props.delay}s`}}>
                    <div className='head flex' onClick={this.handleCardOpen}>
                        <div>
                            <h3>{title}</h3>
                            <div className='details flex'>
                                <span><label>Fecha:</label>{" "}{date}</span>
                                <span><label>Autor:&#32;</label>{" "}{author}</span>
                            </div>
                        </div>
                        <div className='triangle'>🢓</div>
                    </div>
                    <div className='content'>
                        {content}
                    </div>
                </div>
        );
    }
}

export default Card;